import React, { useState, useEffect } from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Element from './element';
import utils from './utils';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const View = (props) => {
    let [url, setUrl] = useState('/home');
    let [tabs, setTabs] = useState([]);
    let [tab, setTab] = useState({});
    let [pedido, setPedido] = useState({});
    let [processo, setProcesso] = useState({});
    let [view, setView] = useState(props.view ? props.view : {});
    let [ready, setReady] = useState(false);
    let [loading, setLoading] = useState(false);
    let [showMenu, setShowMenu] = useState(false);
    let home = { label: '', view: 'home', key: 'home', icon: 'fa fa-list', menu: true, visible: false };
    let user = utils.getUser();
    let [dialog, setDialog] = useState({});
    let [message, setMessage] = useState({});
    let [title, setTitle] = useState({});
    let [actions, setActions] = useState([]);
    let [dialogOpen, setDialogOpen] = useState(false);
    let [idiomas, setIdiomas] = useState([]);
    let [idioma, setIdioma] = useState({});
    let [totalNotificacao, setTotalNotificacao] = useState(0);
    let path = props.path;
    let onReady = props.onReady;
    let [crud, setCrud] = useState({ showMenu: false });
    let [mobile, setMobile] = useState(false);
    let [filial, setFilial] = useState(user.filial);
    let [showDialog, setShowDialog] = useState(false);
    let [filialEdit, setfilialEdit] = useState(false);
    let [popup, setpopup] = useState({});
    let [ispopup, setispopup] = useState(false);

    if (!path) {
        path = window.location.pathname;
    }

    dialog.alert = function (message, title, actions) {
        if (!actions) {
            actions = [];
        }

        if (!title) {
            title = utils.lng('atencao', 'Atenção');
        }
        setTitle(title);
        setMessage(message);
        setActions(actions);
        setDialogOpen(true);
    }


    const actClick = function (act) {
        act.action.call(this);
        setDialogOpen(false);
    }

    dialog.close = function () {
        setDialogOpen(false);

        if (actions.length == 1) {
            actions.forEach(ac => {
                ac.action.call(this);
            });
        }
    }

    window.dialog = dialog;

    const history = useHistory();
    const name = props.name;


    window.idioma = idioma;

    const selecionarIdioma = function (i) {
        if (i) {
            let _ks_ = {};
            _ks_.complete = function (res) {
                if (res && res.data) {
                    let usr = res.data;
                    localStorage.setItem("user", JSON.stringify(usr));
                }
                window.location.reload();
            }
            _ks_.cfg = {
                url: 'language/' + i.id,
                method: 'PUT',
                global: false
            };
            utils.call(_ks_);
        }
    }

    tabs = utils.getTabs();

    if (tabs) {
        for (const ti in tabs) {
            const ta = tabs[ti];
            let cp = ta.view;

            if (cp === path || cp === path + '/' || cp + '/' === path) {
                tab = ta;
            }
        }
    }

    if (!tab || !tab.key) {
        tab = utils.getTab();
    }

    if (!tab) {
        tab = home;
    }


    view.updateTab = function (cfg) {
        if (cfg) {
            for (const i in cfg) {
                tab[i] = cfg[i];
            }
            tab.refresh = true;
            utils.addTab(tab);
            tabs = utils.getTabs();
            window.tab = utils.getTab();

            setTabs(tabs);
        }
    }

    tabs = utils.getTabs();


    const fecharAbas = function () {
        window.localStorage.removeItem('tabs');
        window.location.href = '/home';
    }

    const logout = function () {
        const actions = [];

        actions.push({
            label: utils.lng('cancelar', 'Cancelar'),
            action: function () {

            }
        });

        actions.push({
            label: utils.lng('confirmar', 'Confirmar'),
            action: function () {
                localStorage.clear();
                window.location.reload();
            }
        });

        dialog.alert(utils.lng('deseja_realmente_sair', 'Deseja realmente sair?'), null, actions);
    }

    const label = function (l) {
        if (l) {
            l = l.trim();
            l = l.substr(0, 1).toUpperCase() + l.substr(1).toLowerCase();;
        }
        return l;
    }

    crud.menuClick = function (cfg, args) {
        const _data_ = args.data;

        delete _data_.id;

        if (_data_.entity || _data_.path) {
            utils.open(args.data);
        } else {
            window.location.href = '/home?superior=' + _data_.id_processo;
        }
    }

    view.open = function (cfg, args) {
        const d = args.data;

        if (d.view) {
            utils.open(d);
        } else if (!d.path) {
            const a = {};
            const e = args.entity ? args.entity : d.entity;
            const id = d.id;

            a.id = id;
            a.entity = e;
            a.id_processo = tab.id_processo;

            if (id >= 0) {
                const l = ['login', 'nome', 'descricao', 'chave', 'titulo'];

                for (const i in l) {
                    const v = l[i];
                    const o = {};

                    o.name = v;
                    o.field = v;

                    const x = utils.resolve(o, d);

                    if (x) {
                        a.label = x;
                    }
                }
                a.path = '/crud/' + e + '/' + id;
            } else {
                a.path = '/crud/' + e;
            }
            utils.open(a);
        } else {
            utils.open(d);
        }
    };

    const close = function (t) {
        return utils.close(t);
    };

    view.refresh = function () {
        tabs = utils.getTabs();

        setTabs(tabs);
    };

    view.close = function (element, args) {
        let tab = utils.getTab();

        if (!tab || !tab.key) {
            tab = window.tab;
        }
        return utils.close(tab);
    };


    view.loadingStart = function () {
        setLoading(true);
    };


    view.loadingEnd = function () {
        setLoading(false);
    };

    window.view = view;

    const lateral = {
        name: 'lateral_menu_container',
        elements: {
            menu_overlay: {
                type: 'link',
                rendered: "${crud.showMenu === true}",
                click: {
                    action: 'hideMenu'
                }
            },
            menu_lateral: {
                type: 'list',
                styleClass: 'menu-lateral',
                header: false,
                size: 1000,
                search: false,
                controls: false,
                click: {
                    action: 'menuClick'
                },
                columns: {
                    icon: { type: 'icon' },
                    label: {}
                },
                list: {
                    url: 'listarMenuPrincipal'
                }
            }
        }
    };

    const cookie = {
        name: 'cookie',
        elements: {
            overlay: {},
            left: {
                type: 'span',
                value: 'A plataforma <b>Interpenn</b> utiliza cookies para controle de sessão.<br> Ao continuar você esta de acordo com o uso de cookies.'
            },
            right: {
                elements: {
                    recusar: {
                        space: 6,
                        label: 'Recusar',
                        type: 'button',
                        click: {
                            prompt: 'Ao recusar da política de cookies, você será desconectado da plataforma.',
                            action: 'logout'
                        }
                    },
                    aceitar: {
                        space: 6,
                        label: 'Aceitar',
                        type: 'button',
                        click: {
                            url: 'salvarConfiguracaoUsuario',
                            method: 'POST',
                            redirect: true,
                            alert: false,
                            send: {
                                chave: 'salvar_cookies',
                                valor: 'true'
                            },
                            store: {
                                salvar_cookies: 'valor'
                            }
                        }
                    }
                }
            }
        }
    };

    const onClick = function (t) {
        const a = {};

        a.data = t;

        view.open(null, a);
    };

    crud.data = {};
    crud.changed = {};

    crud.refresh = function () {
        //faz nada
    };

    let exibirMsgCookie = user.id > 0;
    let x = localStorage.getItem('salvar_cookies');

    if (typeof user.salvar_cookies !== 'undefined') {
        exibirMsgCookie = !eval(user.salvar_cookies);
    } else if (typeof x !== 'undefined' && x != null) {
        exibirMsgCookie = !eval(x);
    }

    const mostraMenu = function () {
        setShowMenu(true);
        crud.showMenu = true;
        setCrud(crud);
    }

    let menuClass = "menu-left bg-theme ";

    if (showMenu) {
        menuClass += ' menu-show ';
    }

    if (!ready) {
        setReady(true);

        const onCheck = function () {

            if (user && user.id > 0) {
                utils.execute(view, 'listarPopUps', null, function (res) {
                    for (const i of res.data) {
                        if (i.link_conteudo === window.location.href) {
                            var isView = localStorage.getItem(i.imagem);
                            if (!isView) {
                                setpopup(i)
                                setTimeout(() => { setispopup(true); }, 2000)
                                localStorage.setItem(i.imagem, true);
                            }

                        }
                    }
                });

                if (!props.public) {
                    utils.execute(view, 'encontrarPedido?id_pedido=0', null, function (res) {
                        for (const i in res.data) {
                            setPedido(res.data[i]);
                        }
                    }, false);

                    utils.execute(view, 'listarNotificacoes?count=true', null, function (res) {
                        setTotalNotificacao(res.total);
                    }, false);
                }

                utils.execute(view, 'listarIdiomas', null, function (res) {
                    const d = res.data;

                    setIdiomas(d);
                });
            }
        }

        if (path === '/' && tab.view) {
            path = tab.view;
        }

        if (!props.public) {
            utils.execute(view, 'check?url=' + path, null, function (res) {
                if (res.data) {
                    let d = res.data;

                    window.dicionario = d.dictionary;
                    window.dictionary = d.dictionary;

                    localStorage.setItem('window_dictionary', JSON.stringify(window.dicionario));

                    if (d.language) {
                        idioma = d.language;
                        setIdioma(d.language);
                    }


                    processo = d;

                    if (d.id_processo > 0) {
                        let access = d.access;

                        delete d.access;

                        window.access = access;

                        processo.refresh = false;

                        utils.addTab(processo);

                        if (view.onCheck) {
                            view.onCheck(access);
                        }
                    }

                    if (onReady) {
                        onReady(d);
                    }
                    onCheck();
                    setProcesso(d);
                }

                window.processo = processo;
            }, false);
        }

    }

    crud.hideMenu = function () {
        setShowMenu(false);
        crud.showMenu = false;
    }

    const resize = function () {
        mobile = window.innerWidth <= 990;
        setMobile(mobile);
        window.mobile = mobile;
    }

    const labelFilial = function () {
        let path = window.location.pathname;

        if (pedido && pedido.filial && path && path.indexOf('/carrinho') >= 0) {
            if (window.access)
                if (window.access['EDITAR_FILIAL'] === false) {
                    return pedido.filial;
                } else {
                    return pedido.filial + ' - ' + utils.lng('alterar', 'ALTERAR');
                }
            return pedido.filial + ' - ' + utils.lng('alterar', 'ALTERAR');
        }
        return filial;
    }



    const confirmDialog = function () {
        let _ks_ = {};

        _ks_.cfg = {
            redirect: './carrinho',
            url: 'cart/cancel',
            method: 'POST',
            global: false
        };
        utils.call(_ks_);

        setInterval(
            function () {
                var classDialog = document.getElementsByClassName('MuiDialog-root')[0];
                if (classDialog) {
                    classDialog.style.display = 'none';
                    window.location.replace('/carrinho');
                }
            }, 10
        )
    };

    useEffect(() => {
        window.addEventListener("resize", resize.bind(this));

        const appPushToken = localStorage.getItem('app_push_token');
        const saveToken = function (currentToken) {
            let saved = window.localStorage.getItem('push_token') + '';

            if (saved !== currentToken) {
                let _ks_ = {};

                _ks_.cfg = {
                    url: 'push/registry',
                    method: 'POST',
                    send: {
                        token: currentToken
                    },
                    complete: function (res) {
                        if (res && res.data && res.data.token) {
                            window.localStorage.setItem('push_token', res.data.token);
                        }
                    },
                    global: false
                };
                utils.call(_ks_);
            }
        }

        try {

            if (appPushToken) {
                saveToken(appPushToken);
            } else {
                const messaging = getMessaging();

                onMessage(messaging, (payload) => {
                    console.log(payload);
                });
                getToken(messaging, { vapidKey: 'BN1u2vJ7HJncHyPkTZMdc5tc5kUUOrjMTz0rt9G3toRdH7vhW0cuHzRwZe5mIJJSSn0rxvbNlb1awVeRHgb8z7Q' }).then((currentToken) => {
                    if (currentToken) {
                        saveToken(currentToken);
                    } else {
                    }
                }).catch((err) => {
                });
            }
        } catch (error) {

        }

        resize();
        EditFilialSet();
    }, []);


    const EditFilialSet = function () {
        setInterval(
            function () {

                if (path.startsWith('/carrinho'))
                    if (window.access)
                        if (window.access['EDITAR_FILIAL'] === false) {
                            setfilialEdit(false);
                        } else {
                            setfilialEdit(true);
                        }
            }, 10
        )
    }


    return (
        <>
            <Dialog
                open={dialogOpen}
                onClose={dialog.close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: message
                            }}></div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {actions.length <= 0 &&
                        <Button onClick={dialog.close} color="primary">{utils.lng('fechar', 'FECHAR')}</Button>
                    }
                    {actions.length > 0 &&
                        <>
                            {actions.map((ac, i) => (
                                <Button key={'dlg-act-' + i} variant="contained" onClick={() => { actClick(ac) }} color="primary">
                                    {ac.label}
                                </Button>
                            ))}
                        </>
                    }
                </DialogActions>
            </Dialog>
            {props.full !== true &&
                <>
                    <header>
                        <Navbar bg="white" variant="light" expand="lg" fixed="top">
                            <Navbar.Toggle onClick={mostraMenu} aria-controls="basic-navbar-nav" />
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                {!mobile &&
                                    <>
                                        <Nav className="mr-auto">
                                            {tabs &&
                                                <>
                                                    {tabs.map((i, index) => (
                                                        <>
                                                            {i.visible !== false &&
                                                                <Nav.Link className={i.key === tab.key ? 'tab-item ui-tab-sel' : 'tab-item'} key={'tab-' + i.key} href="#" >
                                                                    <span className="tab-item-click" onClick={() => { onClick(i) }}>
                                                                        {i.icon &&
                                                                            <i className={i.icon + ' ui-tab-icon'} ></i>
                                                                        }
                                                                        {i.label &&
                                                                            <span className="tab-label">
                                                                                {label(i.label)}
                                                                            </span>
                                                                        }
                                                                    </span>
                                                                    <span onClick={() => { close(i) }} className="tab-close" ><i className="fa fa-times" ></i></span>
                                                                </Nav.Link>
                                                            }
                                                        </>
                                                    ))}
                                                </>
                                            }
                                        </Nav>
                                        {user.status_penn != "INATIVO" &&
                                            <span className="ui-icone-notificacoes ui-not">
                                                <a href="/carrinho/favoritos">
                                                    <i className="fa fa-heart"></i>
                                                </a>
                                            </span>
                                        }
                                        {user.status_penn != "INATIVO" &&
                                            <span className="ui-icone-notificacoes ui-not">
                                                <a href="/notificacoes">
                                                    {totalNotificacao > 0 &&
                                                        <span className="ui-notificacoes-total ui-not-total">{totalNotificacao}</span>
                                                    }
                                                    <i className="fa fa-bell"></i>
                                                </a>
                                            </span>
                                        }

                                        {user.status_penn != "INATIVO" && user.exibir_carrinho &&
                                            <span id="quantidade_carrinho" className="ui-icone-carrinho ui-not">
                                                <a href="/carrinho/pedido">
                                                    {pedido.quantidade > 0 &&
                                                        <span className="ui-carrinho-total ui-not-total">{pedido.quantidade}</span>
                                                    }
                                                    <i className="fa fa-shopping-cart"></i>
                                                </a>
                                            </span>
                                        }
                                        <NavDropdown title={
                                            <>
                                                {(idioma && idioma.id > 0) &&
                                                    <div className="pull-left">
                                                        <img className="avatar"
                                                            src={idioma.icone}
                                                            alt="user pic"
                                                        />
                                                    </div>
                                                }
                                            </>
                                        } id="drop-idiomas" className="dropdown-menu-right">
                                            {idiomas.map((i, index) => (
                                                <NavDropdown.Item onClick={() => { selecionarIdioma(i) }}
                                                    className={'ui-idioma ' + (i.id === idioma.id ? 'ui-item-select' : '')}>
                                                    <div className="ui-idioma-item">
                                                        <div className="ui-idioma-label">
                                                            {i.sigla_pais}
                                                        </div>
                                                        <div>&nbsp;/&nbsp;</div>
                                                        <div className="ui-idioma-label">
                                                            {i.descricao}
                                                        </div>
                                                    </div>
                                                </NavDropdown.Item>
                                            ))
                                            }
                                        </NavDropdown>
                                        <NavDropdown title={
                                            <div className="pull-left">
                                                {user.avatar &&
                                                    <img className="avatar"
                                                        src={user.avatar}
                                                        alt="user pic"
                                                    />
                                                }
                                                <span id="username_usuario_logado" className="user-name">{user.login}</span>
                                            </div>
                                        } id="basic-nav-dropdown" className="dropdown-menu-right">
                                            <NavDropdown.Item href="/meus_dados">{utils.lng('meus_dados', 'Meus dados')}</NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item href="/alterar_senha">{utils.lng('alterar_senha', 'Alterar Senha')}</NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item href="#" onClick={fecharAbas}>{utils.lng('fechar_abas', 'Fechar abas')}</NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item href="#" onClick={logout}>{utils.lng('sair', 'Sair')}</NavDropdown.Item>
                                        </NavDropdown>
                                    </>
                                }

                                {mobile &&
                                    <>
                                        <div className="col-6">
                                            <span className="ui-icone-notificacoes ui-not">
                                                <a href="/notificacoes">
                                                    {totalNotificacao > 0 &&
                                                        <span className="ui-notificacoes-total ui-not-total">{totalNotificacao}</span>
                                                    }
                                                    <i className="fa fa-bell"></i>
                                                </a>
                                            </span>
                                            {user.exibir_carrinho &&
                                                <span id="quantidade_carrinho" className="ui-icone-carrinho ui-not">
                                                    <a href="/carrinho/pedido">
                                                        {pedido.quantidade > 0 &&
                                                            <span className="ui-carrinho-total ui-not-total">{pedido.quantidade}</span>
                                                        }
                                                        <i className="fa fa-shopping-cart"></i>
                                                    </a>
                                                </span>
                                            }
                                        </div>
                                        <div className="col-6 navbar-menu-p-right">
                                            <NavDropdown style={{ zIndex: 2, width: '65px' }} title={
                                                <div className="pull-left">
                                                    <img className="avatar"
                                                        src={idioma.icone}
                                                        alt="user pic"
                                                    />
                                                </div>
                                            } id="drop-idiomas" className="dropdown-menu-right">
                                                {idiomas.map((i, index) => (
                                                    <NavDropdown.Item onClick={() => { selecionarIdioma(i) }}
                                                        className={'ui-idioma ' + (i.id === idioma.id ? 'ui-item-select' : '')}>
                                                        <div className="ui-idioma-item">
                                                            <div className="ui-idioma-flag">
                                                                <img src={i.icone}></img>
                                                            </div>
                                                            <div className="ui-idioma-label">
                                                                {i.descricao}
                                                            </div>
                                                        </div>
                                                    </NavDropdown.Item>
                                                ))
                                                }
                                            </NavDropdown>

                                            <NavDropdown title={
                                                <div className="pull-left">
                                                    {user.avatar &&
                                                        <img className="avatar"
                                                            src={user.avatar}
                                                            alt="user pic"
                                                        />
                                                    }
                                                    <span id="username_usuario_logado" className="user-name">{user.login}</span>
                                                </div>
                                            } id="basic-nav-dropdown" className="dropdown-menu-right">
                                                <NavDropdown.Item href="/meus_dados">{utils.lng('meus_dados', 'Meus dados')}</NavDropdown.Item>
                                                <NavDropdown.Divider />
                                                <NavDropdown.Item href="/alterar_senha">{utils.lng('alterar_senha', 'Alterar Senha')}</NavDropdown.Item>
                                                <NavDropdown.Divider />
                                                <NavDropdown.Item href="#" onClick={logout}>{utils.lng('sair', 'Sair')}</NavDropdown.Item>
                                            </NavDropdown>
                                        </div>
                                    </>

                                }
                            </Navbar.Collapse>
                        </Navbar>
                    </header>

                    <div className={menuClass}>
                        <a href={user.url}>
                            <img src="https://storage.googleapis.com/interpennweb-storage/email/images/logon_fundo_branco_vermelha.png" ></img>
                            {/* <img src="/static/img/logo_menu.png" ></img> */}
                        </a>
                        <Element crud={crud} view={view} value={lateral} user={user}></Element>
                    </div>
                </>
            }
            <section className={props.full ? 'view-data ui-view-full' : 'view-data'}>
                {
                    <>
                        {!mobile &&
                            <>
                                {filialEdit &&
                                    <span className="user-filial user-filial-hv" onClick={confirmDialog}>{labelFilial()}</span>
                                }


                                {!filialEdit &&
                                    <span className="user-filial">{labelFilial()}</span>
                                }

                            </>
                        }
                    </>
                }
                <Dialog
                    className="ui-dialog-loading"
                    open={loading}>
                    <DialogContent>
                        <div className="ui-view-loading-data">
                            <i className="fas fas fa-circle-notch fa-spin"></i>
                        </div>
                    </DialogContent>
                </Dialog>
                {(processo.id_processo > 0 || processo.admin || processo.skip) &&
                    <div className={'ui-view ui-view-' + (name ? name : tab.name) + ' ' + (props.className ? props.className : '')}>
                        {props.children}
                    </div>
                }
            </section>
            {(exibirMsgCookie && !props.public) &&
                <div className="ui-cookie-bar">
                    <Element crud={crud} view={view} value={cookie} user={user} ></Element>
                </div>
            }
            {popup &&
                <>
                    {(ispopup && popup.imagem) &&
                        <div className="ui-pop-up-geral">
                            <div className="ui-pop-up-fundo" onClick={() => { setispopup(false) }}></div>
                            <div className="ui-pop-up">
                                <div>
                                    <IconButton aria-label="close" className="close" onClick={() => { setispopup(false) }}>
                                        <CloseIcon />
                                    </IconButton>
                                    <Button className="ui-pop-up-button" onClick={() => { window.location.href = popup.link_redirecionamento }}>
                                        <img src={popup.imagem} />
                                    </Button>
                                </div>
                            </div>

                        </div>
                    }
                </>

            }
        </>
    )
};

export default View;