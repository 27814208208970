import React, { useState, useEffect } from "react";
import View from './view';


const BlogTvNipponflex = function () {

  return (
    <View styleClass="ui-full-page" name="blog">
      <iframe src="https://tv.nipponflex.com/"  style={{border: 'none',width: '100%', height: '100%'}}></iframe>
    </View>
  );
}

export default BlogTvNipponflex;