String.prototype.replaceAll = function (search, replacement) {
    var target = this;
    return target.split(search).join(replacement);
};


Date.prototype.getDateString = function (format) {
    const mm = this.getMonth() + 1; // getMonth() is zero-based
    const dd = this.getDate();
    let hh = this.getHours();
    let mi = this.getMinutes();
    let ss = this.getSeconds();

    if (hh < 10) {
        hh = '0' + hh;
    }

    if (mi < 10) {
        mi = '0' + mi;
    }

    if (ss < 10) {
        ss = '0' + ss;
    }

    let part = '';

    if (format !== 'date') {
        part = (' ' + hh + ':' + mi + ':' + ss);
    }

    return [(dd > 9 ? '' : '0') + dd,
    (mm > 9 ? '' : '0') + mm,
    this.getFullYear()
    ].join('/') + part;
};

let utils = {
    isValidDate: function (d) {
        return d instanceof Date && !isNaN(d);
    },
    toDate: function (v) {
        let d = null;
        if (v != null) {
            try {
                if (typeof v === 'object') {
                    d = v;
                } else if (typeof v === 'string') {
                    v = v.trim();

                    let parts = [];
                    let hh = 0;
                    let mm = 0;
                    let ss = 0;
                    let dia = 0;
                    let ano = 0;
                    let aux = [];

                    if (v.indexOf('T') > 0 || v.indexOf('+') > 0) {
                        d = new Date(v);
                    } else if (v.indexOf('-') > 0) {
                        let split = v.split('-');
                        let ano = parseInt(split[0]);
                        let mes = parseInt(split[1]);
                        let dia = parseInt(split[2]);
                        d = new Date(ano, mes - 1, dia);
                    } else {
                        aux = v.split(' ');
                        parts = aux[0].split('/');
                        ano = parts[2];
                        dia = parts[0];

                        if (aux.length > 1) {
                            let hours = aux[1].split(':');

                            hh = parseInt(hours[0]);
                            mm = parseInt(hours[1]);

                            if (hours.length > 2) {
                                ss = parseInt(hours[2]);
                            }
                        }

                        let mes = parseInt(parts[1]);

                        d = new Date(ano, mes - 1, dia, hh, mm, ss);
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        if (utils.isValidDate(d)) {
            return d;
        }
        return null;
    },
    mobileDownload: function (url) {
        let cordova = window.cordova;
        let fileURL = cordova.file.documentsDirectory;

        var fileTransfer = new window.FileTransfer();
        var uri = encodeURI(url);

        fileTransfer.download(
            uri,
            fileURL,
            function (entry) {
                console.log("download complete: " + entry.toURL());
            },
            function (error) {
                console.log("download error source " + error.source);
                console.log("download error target " + error.target);
                console.log("download error code" + error.code);
            },
            false
        );
    },
    print: function (clientId) {
        const print = window.open('', 'PRINT', 'height=400,width=800');

        print.document.body.innerHTML = '<body>' + document.getElementById(clientId).innerHTML + '</body>';

        print.document.close(); // necessary for IE >= 10
        print.focus(); // necessary for IE >= 10*/
        print.print();
        print.close();
    },
    onlyNumber: function (v, money) {
        if (typeof v === 'undefined' || v === null) {
            v = '';
        }
        v = v + '';
        v = v.replace('.', ',');

        let split = v.split('');
        let nv = '';

        for (const i in split) {
            const so = split[i];
            const vi = parseInt(so);

            if (money && ','.indexOf(so) >= 0 && nv.indexOf(',') < 0) {
                nv = nv + so;
            } else if (!isNaN(vi)) {
                nv = nv + vi;
            }
        }
        return nv;
    },
    parseMoney: function (prefix, n) {
        if (!n) {
            n = '';
        }
        let v = utils.onlyNumber(n, true);

        v = (v + '').replace('.', ',');

        return prefix + ' ' + v;
    },
    appyMask: function (value, mask) {
        let numbers = utils.onlyNumber(value);
        let maskSplit = mask.split('');
        let nv = '';
        let count = 0;

        if (mask && mask.indexOf("***") >= 0) {
            return value;
        } else {
            for (const i in maskSplit) {
                const vi = maskSplit[i];
                let ch = vi;

                if ('1234567890?'.indexOf(vi) >= 0) {
                    if (numbers[count]) {
                        let a = parseInt(numbers[count]);
                        let b = parseInt(vi);

                        if (a > b) {
                            ch = '';
                        } else {
                            ch = numbers[count];
                        }
                    } else {
                        ch = '';
                    }
                    count++;
                }
                if (numbers.length >= count) {
                    nv = nv + ch;
                }
            }
        }
        if (nv.indexOf(value) >= 0) {
            return value;
        }
        if (nv && nv.length > 0) {
            let v = parseInt(nv.substring(nv.length - 1, nv.length));

            if (isNaN(v)) {
                return nv.substring(0, nv.length - 1);
            }
        }
        return nv;
    },
    contains: function (query, str, separator) {
        if (!query || !str) {
            return false;
        }
        if (!separator) {
            separator = '|';
        }
        if (str.indexOf(separator) < 0) {
            str = separator + str + separator;
        }
        return query.indexOf(str) >= 0;
    },
    getPathId: function (pos) {
        const path = window.location.pathname;
        const split = path.split('/');
        let id = 0;

        if (split.length > pos) {
            try {
                id = parseInt(split[pos]);

                if (!(id > 0)) {
                    id = 0;
                }
            } catch (x) {
            }
        }
        return id;
    },
    getPath: function (pos) {
        const path = window.location.pathname;
        const split = path.split('/');

        if (split.length > pos) {
            return split[pos];
        }
        return '';
    },
    getParam: function (key) {
        var url_string = window.location.href;

        var url = new URL(url_string);

        return url.searchParams.get(key);
    },
    clone: function (args) {
        const json = JSON.stringify(args);

        return JSON.parse(json);
    },
    copy: function (elementId) {

        var textToCopy = document.getElementById(elementId).innerHTML;
        // Create an auxiliary hidden input
        var aux = document.createElement("textArea");

        // Get the text from the element passed into the input
        aux.value = textToCopy;
        aux.setAttribute("value", textToCopy);

        // Append the aux input to the body
        document.body.appendChild(aux);

        // Highlight the content
        aux.select();

        // Execute the copy command
        // document.execCommand("copy");

        if (navigator.clipboard) {
            navigator.clipboard.writeText(textToCopy).then(
                () => {
                    window.dialog.alert(utils.lng('copiado_area_transferencia', 'Copiado para área de transferência'))
                    // Remove the input from the body
                    document.body.removeChild(aux);
                },
                (err) => { 
                    console.error("Failed to copy", err); 
                    // Remove the input from the body
                    document.body.removeChild(aux);
                }
            )
        } else {
            console.error("Failed to copy");
        }
    },
    close: function (tab) {
        let cur = utils.getTab();
        let back = tab.back;
        let a = {};
        let tabs = utils.getTabs();

        tabs.forEach(e => {
            a[e.key] = e;
        });

        let same = (cur && tab.key === cur.key);

        delete a[tab.key];

        tabs = [];

        for (const i in a) {
            if (a[i]) {
                tab = a[i];

                tabs.push(tab);
            }
        }
        localStorage.setItem('tabs', JSON.stringify(tabs));

        if (a[back]) {
            utils.open(a[back]);
        } else {
            utils.open(tab);
        }
        return false;
    },
    getTab: function () {
        let str = localStorage.getItem('tab');
        let tab = null;

        if (str) {
            tab = JSON.parse(str);
        }
        return tab;
    },
    getTabs: function () {
        let str = localStorage.getItem('tabs');
        let tabs = [];

        if (str) {
            tabs = JSON.parse(str);
        }
        return tabs;
    },
    addTab: function (b) {
        let old = utils.getTab();
        let back = null;
        let tabs = utils.getTabs();
        let a = {};

        if (old) {
            back = old.key;
        }

        if (!b.label) {
            return;
        }

        if (!b.key) {
            if (b.id >= 0) {
                b.key = b.entity + '_' + b.id;
            } else {
                delete b.id;

                b.key = b.entity;
            }
        }

        tabs.forEach(e => {
            a[e.key] = e;
        });

        if (!a[b.key]) {
            a[b.key] = b;

            b.back = back;

            tabs.push(b);

        } else if (b.refresh !== false) {
            const o = a[b.key];

            if (b.label) {
                o.label = b.label;
            }

            b = o;
        } else {
            b = a[b.key];
        }

        localStorage.setItem('tabs', JSON.stringify(tabs));
        localStorage.setItem('tab', JSON.stringify(b));

        return tabs;
    },
    open: function (b) {
        let url = '/home';

        if (b.view) {
            url = b.view;
        } else if (b.url) {
            url = b.url;
        } else if (b.path) {
            url = b.path;
        } else if (b.entity) {
            if (b.id) {
                url = '/crud/' + b.entity + '/' + b.id;
            } else {
                url = '/crud/' + b.entity + '/';
            }
        }

        window.location.href = url;
    },
    get: (field, _data_) => {
        try {
            return eval('_data_.' + field);
        } catch (error) {

        }
        return null;
    },
    set: (field, data, val, merge) => {
        try {
            const split = field.split('.');

            let a2 = data;
            let s = split.length;

            for (let i = 0; i < s; i++) {
                const a = split[i];

                if (i == s - 1) {
                    if (val != null && typeof val === 'object' && !val.push && merge) {
                        if (!a2[a]) {
                            a2[a] = {};
                        }
                        for (const vi in val) {
                            a2[a][vi] = val[vi];
                        }
                    } else {
                        a2[a] = val;
                    }
                } else {
                    if (!a2[a]) {
                        a2[a] = {};
                    }
                    a2 = a2[a];
                }

            }
        } catch (error) {

        }
    },
    getUser: function () {
        let jsonUser = localStorage.getItem('user');
        let user = {};

        if (jsonUser) {
            user = JSON.parse(jsonUser);
            window.user = user;
        }
        return user;
    },
    getCliente: function () {
        let usr = this.getUser();
        let cliente = {};
        if (usr) {
            cliente = usr.cliente;
        }
        return cliente;
    },
    getIdPaisUser: function () {
        let usr = this.getUser();
        return usr.id_pais;
    },
    getPaisUser: function () {
        let usr = this.getUser();
        return usr.codigo_pais;
    },
    decode: (element, crud, data) => {
        let decoded = {};
        let user = utils.getUser();
        let nvl = function (a, b) {

            if (a === null || typeof a === 'undefined' || a === '') {
                return b;
            }
            return a;
        }
        let last = function (list, b) {
            let v = null;

            if (list) {
                for (const i in list) {
                    v = list[i];
                }
            }
            return v;
        }

        let exists = function (obj, key) {
            if (typeof obj === 'object') {
                try {
                    let v = eval('obj.' + key);

                    if (v != null && typeof v !== 'undefined') {
                        if (typeof v === 'string' && v.trim() === '') {
                            return false;
                        }
                        return true;
                    }
                } catch (error) {

                }
            }
            return false;
        };

        let isEmpty = function (obj) {
            if (typeof obj === 'string' && (obj + '').trim() !== '') {
                return false;
            }
            return true;
        };

        let sizeOf = function (list) {
            if (typeof list === 'object' && list.push) {
                return list.length;
            }
            return 0;
        };

        let equals = function (obj, key, comparableValue) {
            if (typeof obj === 'object') {
                try {
                    let expressionValue = eval('obj.' + key);

                    if (expressionValue != null && typeof expressionValue !== 'undefined') {
                        return expressionValue === comparableValue;
                    }
                } catch (error) {
                    return false;
                }
            }
            return false;
        };

        window.isEmpty = isEmpty;
        window.empty = isEmpty;
        window.exists = exists;
        window.sizeOf = sizeOf;
        window.nvl = nvl;
        window.last = last;


        if (typeof element === 'object' && element !== null) {
            let vars = '';

            if (element.itemVar) {
                vars += 'let ' + element.itemVar + ' = data; ';
            }

            for (const ki in data) {
                if ('|crud|new|edit|'.indexOf('|' + ki + '|') < 0) {
                    vars += 'let ' + ki + ' = data.' + ki + '; ';
                }
            }

            for (const k in element) {
                const a = element[k];
                let c = '|' + k + '|';
                let b = a;

                if ('|elements|'.indexOf(c) < 0 && '|columns|'.indexOf(c) < 0 && '|filters|'.indexOf(c) < 0) {
                    if (typeof a === 'string') {
                        b = b.replaceAll('${', '#{');
                        b = b.replaceAll('_this_', 'crud');
                        b = b.replaceAll('@this', 'data');
                        b = b.replaceAll('@tab', 'window.processo');
                        b = b.replaceAll('is empty ', '"" == ');
                        b = b.replaceAll('not empty ', '"" != ');
                        b = b.replaceAll('\n', ' ');
                        b = b.replaceAll(' eq ', ' == ');
                        b = b.replaceAll(' ne ', ' != ');
                        b = b.replaceAll(' or ', ' || ');
                        b = b.replaceAll('empty ', '"" == ');
                        b = b.replaceAll('obj', 'data');

                        let _c_ = 0;

                        if (b.indexOf('#{') > 0) {
                            const regex = /#\{([\w.=!>< ?:'"-_ÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑáàâãéèêíïóôõöúçñ]{1,})\}/gm;
                            let m;
                            let val = null;
                            let a = b;

                            while ((m = regex.exec(a)) !== null) {
                                if (m.index === regex.lastIndex) {
                                    regex.lastIndex++;
                                }
                                const find = m[0];
                                const match = m[1];

                                try {
                                    val = eval(match);
                                } catch (e) {
                                    val = '';
                                }
                                b = b.replaceAll(find, val + '');
                                _c_ = _c_ + 1;
                            }
                        } else if (b.indexOf('#{') >= 0) {
                            const ex = b.substring(2, b.length - 1);
                            try {
                                b = eval(vars + '' + ex);
                            } catch (error) {
                                b = '';
                            }

                        } else if (b.indexOf('{') >= 0) {
                            //                            for (const i in data) {
                            //                                let xi = data[i];
                            //                                let xv = '{' + i + '}';
                            //
                            //                                if (b.indexOf(xv) >= 0) {
                            //                                    b = b.replace(xv, xi + '');
                            //                                }
                            //                            }
                        }

                        if (typeof b === 'string') {
                            b = b.replaceAll('undefined', '');
                        }
                    } else if (typeof a === 'object') {
                        if (a != null && a.push) {
                            const ax = [];

                            for (const i in b) {
                                const bi = utils.decode(b[i], crud, data)

                                ax.push(bi);
                            }
                            b = ax;
                        } else {
                            b = utils.decode(b, crud, data);
                        }
                    }
                    decoded[k] = b;
                }
            }
        }
        return decoded;
    },
    lng: (key, value) => {
        if (typeof window.dicionario !== 'undefined' && key) {
            if (window.dicionario[key]) {
                return window.dicionario[key];
            }
        } else {
            let dicionario = localStorage.getItem('window_dictionary');
            if (dicionario) {
                try {
                    window.dicionario = JSON.parse(dicionario);
                    window.dictionary = window.dicionario;
                    if (window.dicionario && window.dicionario[key]) {
                        return window.dicionario[key];
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        }
        return value;
    },
    label: (data) => {
        const keys = ['label', 'nome', 'descricao', 'chave'];

        for (const i in keys) {
            const k = keys[i];
            const v = data[k];

            if (v) {
                return v;
            }
        }
        for (const i in data) {
            const v = data[i];

            if (typeof v === 'object') {
                const x = utils.label(v);

                if (x) {
                    return x;
                }
            }
        }
        return null;
    },
    resolveField: (field, data) => {
        if (!field) {
            field = 'id';
        }

        let v = data[field];

        if (!v) {
            v = data[field.toLowerCase()];
        }

        if (field && field.indexOf('.') >= 0) {
            try {
                v = eval('data.' + field);
            } catch (error) {
                return '';
            }
        }

        if (field === 'cliente' && data.cliente && data.cliente.pessoa) {
            try {
                v = data.cliente.pessoa;
            } catch (error) {
                return '';
            }
        }

        if (typeof v === 'undefined') {
            v = '';
        }

        if (typeof v === 'boolean') {
            return v ? 'Sim' : 'Não';
        } else if (typeof v === 'object') {
            if (v !== null) {

                if (v.length > 0) {
                    v = v[0];
                }

                const keys = ['label', 'nome', 'descricao', 'chave', 'numero'];

                for (const i in keys) {
                    const n = keys[i];

                    if (v[n]) {
                        return v[n];
                    }
                }
                let label = [];

                for (const i in v) {
                    const n = v[i];

                    if (n && typeof n === 'object') {
                        const keys = ['label', 'nome', 'descricao', 'chave', 'numero'];

                        for (const i in keys) {
                            const ki = keys[i];

                            if (n[ki]) {
                                label.push(n[ki]);
                            }
                        }
                    }
                }

                if (label.length > 0) {
                    return label.join(' - ');
                }

                if (v.codigo) {
                    label.push(v.codigo + '');
                } else if (v.id) {
                    label.push(v.id + '');
                }

                for (const i in v) {
                    if ('|id|codigo|senha|passswod|'.indexOf('|' + i + '|') < 0) {
                        const n = v[i];

                        if (typeof n === 'string' && n && label.length < 2) {
                            label.push(n);
                        }
                    }
                }

                if (label.length > 0) {
                    return label.join(' - ');
                }
            }
        } else {
            return v;
        }
    },
    resolve: (element, data) => {
        if (element.value) {

        } else {
            let field = element.field ? element.field : element.name;
            let v = utils.resolveField(field, data);

            if (element.format === 'money') {
                v = utils.money(v);
            }
            if (element.format === 'number') {
                v = utils.number(v, element.decimalCount ? element.decimalCount : 2);
            }
            return v;
        }

        if (data.codigo) {
            return data.codigo;
        }

        if (data.id) {
            return data.id;
        }
        return '';
    },
    prompt: function (cfg, _execute_, _cancel_) {
        if (cfg) {
            if (cfg.prompt) {
                const dialog = window.dialog;
                const msg = utils.lng(cfg.prompt, cfg.prompt);
                const actions = [];

                actions.push(
                    {
                        label: utils.lng('cancelar', 'Cancelar'),
                        action: function () {
                            if (_cancel_) {
                                _cancel_();
                            }
                        }
                    }, {
                    label: utils.lng('confirmar', 'Confirmar'),
                    action: _execute_
                });
                if (dialog) {
                    dialog.alert(msg, null, actions);
                } else {
                    _execute_();
                }
            } else {
                _execute_();
            }
        } else if (_execute_) {
            _execute_();
        }
    },
    loadLanguage: function (view) {

    },
    execute: function (crud, url, fields, complete, global) {
        const _ks_ = {};

        _ks_.global = global;
        _ks_.crud = crud;

        if (crud.sendChanged) {
            _ks_.changed = crud.changed ? crud.changed : {};
        } else {
            _ks_.changed = {};
        }

        _ks_.element = {};

        _ks_.complete = function (res) {
            if (complete) {
                complete.call(this, res);
            }

            if (crud && crud.refresh) {
                crud.refresh();
            }
        };

        _ks_.cfg = {
            url: url,
            fields: fields,
            method: 'GET'
        };
        utils.call(_ks_);
    },
    merge: function (origem, destino) {
        for (const i in origem) {
            let va = origem[i];
            let vb = destino[i];

            if (va == null || typeof va !== 'object') {
                destino[i] = va;
            } else {
                if (typeof vb === 'undefined') {
                    vb = {};

                    destino[i] = vb;
                }
                utils.merge(va, vb);
            }
        }
    },
    nvl: function (...args) {
        for (const i in args) {
            if (typeof args[i] !== 'undefined' && args[i] !== null) {
                return args[i];
            }
        }
        return null;
    },
    money: function (amount, decimalCount = 2, decimal = ",", thousands = ".") {
        try {
            let currency_symbol = 'R$';

            if (window.dicionario) {
                currency_symbol = utils.nvl(window.dicionario.currency_symbol, currency_symbol);
            }
            //Paraguai, não mostrar casas decimais
            if (currency_symbol == '₲') {
                decimalCount = 0;
            }
            return currency_symbol + ' ' + this.number(amount, decimalCount, decimal, thousands);
        } catch (e) {
            console.log(e)
        }
        return '';
    },
    customMoney: function (currency_symbol, amount, decimalCount = 2, decimal = ",", thousands = ".") {
        try {
            return currency_symbol + ' ' + this.number(amount, decimalCount, decimal, thousands);
        } catch (e) {
            console.log(e)
        }
        return '';
    },
    // Mesma função que o 'money' mas sem os centavos - util para Guarani
    moneyCents: function (amount, decimalCount = 0, decimal = ",", thousands = ".") {
        try {
            let currency_symbol = 'R$';

            if (window.dicionario) {
                currency_symbol = utils.nvl(window.dicionario.currency_symbol, currency_symbol);
            }
            return currency_symbol + ' ' + this.number(amount, decimalCount, decimal, thousands);
        } catch (e) {
            console.log(e)
        }
        return '';
    },
    currencySymbol: function () {
        try {
            let currency_symbol = 'R$';
            if (window.dicionario) {
                currency_symbol = utils.nvl(window.dicionario.currency_symbol, currency_symbol);
            }
            return currency_symbol;
        } catch (e) {
            console.log(e);
        }
        return 'R$';
    },
    number: function (amount, decimalCount = 2, decimal = ",", thousands = ".") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";

            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;

            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.log(e)
        }
        return '';
    },
    linkDownload: function (path) {
        let url = utils.getUrlApi();

        let token = localStorage.getItem('token');

        if (!token) {
            token = utils.getParam('token');
        }

        return url + path + '?token=' + token;
    },
    getUrlApi: function () {
        let protocol = window.location.protocol;
        let url = protocol + '//crm.conecteme.com.vc/api/';
        let host = window.location.hostname;

        if (host.indexOf('mac.') >= 0) {
            url = protocol + '//api.macbook:8080/api/';
        } else if (host.indexOf('vb.') >= 0) {
            url = protocol + '//api.' + host + ':8080/api/';
        } else if (host.indexOf('127.0.0.10') >= 0) {
            url = 'https://api.nipponflex.com/api/';
        } else if (host.indexOf('local') >= 0) {
            url = protocol + '//localhost:8080/api/';
        } else if (host.indexOf('dev') >= 0 || host.indexOf('desenv') >= 0 || host.indexOf('127.0.') >= 0) {
            url = 'https://dev.conecte.com.vc/api/';
        } else {
            url = 'https://api.nipponflex.com/api/';
        }
        return url;
    },
    call: async (_args_) => {
        const global = _args_.global === true;
        const element = _args_.element ? _args_.element : {};
        const cfg = _args_.cfg;
        const complete = _args_.complete ? _args_.complete : cfg.complete;
        let onerror = _args_.onerror;
        const onstart = _args_.onstart;
        let cache = cfg.cache;
        let data = {};
        let idioma = {};
        let changed = _args_.changed;
        let params = {};
        let redirect = cfg.redirect;


        if (!onerror) {
            onerror = cfg.onerror;
        }

        if (_args_.data) {
            for (const i in _args_.data) {
                data[i] = _args_.data[i];
            }
        }

        const validate = _args_.validate;
        const crud = _args_.crud;
        const view = window.view;
        const dialog = window.dialog;
        const parentId = _args_.parent ? _args_.parent : 0;


        let jsonIdioma = localStorage.getItem('idioma');

        if (jsonIdioma) {
            idioma = JSON.parse(jsonIdioma);
        }


        if (crud && validate) {
            let ok = true;

            for (const i in crud.elements) {
                const a = crud.elements[i];

                if (a.isRequired()) {
                    ok = false;
                }
            }
            if (!ok) {
                if (dialog) {
                    dialog.alert(utils.lng('alguns_erros_encontrados', 'Alguns erros foram encontrados'), onerror);
                } else if (onerror) {
                    onerror.call(this);
                }
                return;
            }
        }
        if (!data) {
            data = {};
        }
        if (!changed) {
            changed = {};
        }

        let customParams = false;
        const merge = {};

        for (const i in data) {
            merge[i] = data[i];
        }

        for (const i in changed) {
            merge[i] = changed[i];
        }

        if (cfg.send) {

            let send = cfg.send;

            if (!send.push) {
                send = utils.decode(cfg.send, crud, merge, {});
            }

            if (send) {
                for (const x in send) {
                    let c = x;

                    if (cfg.send.push) {
                        c = send[x];
                    }

                    let a = send[c];
                    let v = merge[a];

                    if (typeof v === 'undefined') {
                        v = a;
                    }

                    if (typeof v === 'undefined') {
                        v = merge[c];
                    }

                    if (typeof v !== 'undefined') {
                        params[c] = v;
                    }
                    customParams = true;
                }
            }
        }

        if (!customParams && cfg.params !== false) {
            for (const i in changed) {
                params[i] = changed[i];
            }
        }

        if (_args_.search) {
            for (const i in _args_.search) {
                params[i] = _args_.search[i];
            }
        }

        const e1 = utils.decode(cfg.params, crud, merge, {});

        if (e1) {
            for (const i in e1) {
                params[i] = e1[i];
            }
        }

        const e2 = utils.decode(element.params, crud, merge, {});

        if (e2) {
            for (const i in e2) {
                params[i] = e2[i];
            }
        }

        if (element.card) {
            params.id_card = element.card;
        }

        if (element.crud) {
            params.id_crud = element.crud;
        }

        let query = '';
        let url = utils.getUrlApi();

        if (cfg.base) {
            url = cfg.base;
        }

        //url = protocol + '//stable.conecte.com.vc/api/';

        if (cfg.fields && cfg.fields.length > 0) {
            params.fields = cfg.fields.join(',');
        }

        let method = cfg.method ? cfg.method : 'GET';
        let body = null;
        let res = {};
        let id = data.id ? data.id : 0;

        if (element && element.loadingStart) {
            element.loadingStart();
        }

        let _onComplete_ = function (res) {

            if (typeof complete === 'function') {
                complete.call(this, res);
            } else if (typeof complete === 'string') {
                crud[complete].call(this, res);
            }

            if (!res.error) {

                if (_args_.event !== 'list' && res.data && data) {
                    for (const i in res.data) {
                        data[i] = res.data[i];
                    }
                }

                const _store_ = cfg.store;
                const _define_ = cfg.define;

                if (_define_ || _store_) {

                    if (_define_) {
                        window[_define_] = res.data;
                    }

                    if (typeof _store_ === 'object') {
                        for (const i in _store_) {
                            const v = _store_[i];

                            window[i] = res.data[v];
                            localStorage.setItem(i, JSON.stringify(res.data[v]));
                        }

                    } else {
                        window[_store_] = res.data;


                        if (_store_ && res.data) {
                            localStorage.setItem(_store_, JSON.stringify(res.data));
                        }
                    }
                }

                if (cache && res.data) {
                    localStorage.setItem(cache, JSON.stringify(res.data));
                }

                if (res.token) {
                    localStorage.setItem('token', res.token);
                }

                if (crud && crud.refresh) {
                    crud.refresh();
                }

                if (res.redirect) {
                    window.location.href = res.redirect;
                } else if (typeof redirect === 'string') {

                    if (redirect.indexOf('$') >= 0 || redirect.indexOf('#') >= 0) {
                        const tmp = utils.decode(cfg, crud, merge);

                        redirect = tmp.redirect;
                    }
                    for (const i in merge) {
                        redirect = redirect.replace('{' + i + '}', merge[i]);
                    }
                }

                if (redirect === true) {
                    setTimeout('window.location.reload()', 500);
                } else if (typeof redirect === 'string') {
                    if (cfg.newWindow) {
                        window.open(redirect, "_blank");
                    } else {
                        window.location.href = redirect;
                    }
                }
            } else if (res.status == 403) {
                localStorage.clear();
                window.location.reload();
            }
        };

        let endCall = function (res) {
            if (view && view.loadingEnd && global) {
                view.loadingEnd();
            }

            element.loading = false;

            if (element.loadingEnd) {
                element.loadingEnd();
            }


            if (cfg.download) {
                _onComplete_(res);

                let body = res.data;
                let url = body?.data?.link;

                if (!url) {
                    try {
                        url = window.URL.createObjectURL(body);
                    } catch (e) {
                        console.log(e);
                    }
                }

                if (url) {
                    var link = document.createElement('a');

                    link.href = url;
                    link.target = '_blank';
                    link.download = cfg.fileName;
                    link.click();

                    setTimeout(function () {
                        // For Firefox it is necessary to delay revoking the ObjectURL
                        window.URL.revokeObjectURL(url);
                    }, 100);
                }
            } else {

                if (typeof res.data === 'undefined') {
                    res.data = [];
                }
                let msg = res.message;
                let _alert_ = cfg.alert !== false || (res.error === true && method !== 'GET');

                if (res.errors && res.errors.length > 0) {
                    msg = '';

                    for (const i in res.errors) {
                        msg += '<div class="dlg-msg-error">' + res.errors[i].message + '</div>';
                    }
                }

                if (msg && _alert_) {
                    const actions = [];

                    actions.push({
                        label: utils.lng('confirmar', 'Confirmar'),
                        action: function () {
                            _onComplete_(res);
                        }
                    })

                    if (dialog) {
                        dialog.alert(msg, null, actions);
                    } else {
                        _onComplete_(res);
                    }
                } else {
                    _onComplete_(res);
                }
            }
        };

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Path': window.location.pathname
        };

        headers['X-Language'] = navigator.language || navigator.userLanguage;

        if (typeof _args_.size !== 'undefined') {
            params.size = _args_.size;
        }

        if (method !== 'GET') {
            const a = _args_.body ? _args_.body : {};

            for (const i in a) {
                params[i] = a[i];
            }

            body = JSON.stringify(params);
        } else if (params) {
            for (const i in params) {
                const v = params[i];

                if (cfg.send !== false || '|size|page|query|fields|'.indexOf(i) > 0) {
                    if (typeof v !== 'undefined' && typeof v !== 'object' && (v + "").indexOf("data:") != 0) {
                        query += i + '=' + encodeURIComponent(v) + '&';
                    } else if (i === 'filters' && v) {
                        for (const ii in v) {
                            const vs = v[ii];

                            query += 'filters[' + ii + ']=' + encodeURIComponent(vs) + '&';
                        }
                    }
                }
            }
            let url = cfg.url;

            if (query && url) {
                if (url.indexOf('?') >= 0) {
                    query = '&' + query;
                } else {
                    query = '?' + query;
                }
            }
        }

        let _execute_ = async function () {
            let tmp = utils.decode(cfg, crud, merge);
            let curl = tmp.url;

            if (!curl) {
                curl = cfg.url;
            }

            if (curl) {
                if (onstart) {
                    onstart.call(this);
                }

                if (cfg.form) {
                    body = cfg.form;

                    headers = {};
                }

                let uri = url + curl + query;
                let token = localStorage.getItem('token');

                if (!token) {
                    token = utils.getParam('token');
                }

                uri = uri.replace('{id}', id);
                uri = uri.replace('{parent}', parentId);

                for (const i in data) {
                    uri = uri.replace('{' + i + '}', data[i]);
                }

                if (token) {
                    headers.Authorization = 'Bearer ' + token;
                }

                if (element && element.loadingStart) {
                    element.loadingStart();
                }

                if (view && view.loadingStart && global) {
                    view.loadingStart();
                }

                let valCache = null;

                if (cache) {
                    let jsonCache = localStorage.getItem(cache);

                    if (jsonCache) {
                        valCache = JSON.parse(jsonCache);
                    }
                }

                if (valCache) {
                    endCall({ data: valCache, error: false });
                } else if (cfg.await) {
                    let resp = await fetch(uri, {
                        method: method,
                        headers: headers,
                        body: body
                    });

                    res = await resp.json();

                    endCall(res);
                } else {

                    fetch(uri, {
                        method: method,
                        headers: headers,
                        body: body
                    }).then(function (res) {
                        if (cfg.download) {
                            const contentType = res.headers.get('content-type');
                            let isDownloadJson = contentType && contentType.includes('application/json') 
                                                && (
                                                    uri.indexOf('/api/contrato/') > -1 
                                                    || uri.indexOf('/api/convite_convensao/') > -1 
                                                    || uri.indexOf('/report/') > -1
                                                );
                            if (isDownloadJson) {
                                return res.json();
                            } else {
                                return res.blob();
                            }
                        }
                        return res.json();
                    }).then(function (res) {

                        if (cfg.download) {
                            res = {
                                error: false,
                                data: res,
                            }
                        }
                        element.loading = false;
                        endCall(res);
                    }).catch(function (res) {
                        console.log(res)
                    });
                }
            } else if (cfg.action) {
                if (cfg.action === 'logout') {
                    endCall({ data: null });
                    localStorage.clear();
                    window.location.reload();
                }
            } else {
                if (typeof redirect === 'string') {

                    if (redirect.indexOf('$') >= 0 || redirect.indexOf('#') >= 0) {
                        redirect = tmp.redirect;
                    }
                    for (const i in merge) {
                        redirect = redirect.replace('{' + i + '}', merge[i]);
                    }
                }

                if (redirect === true) {
                    window.location.reload();
                } else if (typeof redirect === 'string') {
                    if (cfg.newWindow) {
                        window.open(redirect, "_blank");
                    } else {
                        window.location.href = redirect;
                    }
                }
            }
        }

        utils.prompt(cfg, _execute_, function () {
            element.loading = false;

            if (typeof complete === 'function') {
                complete.call(this, res);
            }
        });
    },
    isUrlContains: function (stringPath) {
        let url = window.location.pathname + "";
        return url.indexOf(stringPath) > -1;
    },
    isChanged: function (changedProps) {
        for (const prop in changedProps) {
            return true;
        }
        return false;
    },
    getToken: function () {
        let token = localStorage.getItem('token');
        if (!token) {
            token = utils.getParam('token');
        }
        return token;
    },
};

export default utils;
