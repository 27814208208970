import React, { useState, useEffect } from "react";
import View from './view';
import utils from './utils';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Element from './element';
import Button from '@material-ui/core/Button';

function CampanhasPremiacaoCupons() {
  let [view, setView] = useState({});
  let [ready, setReady] = useState(false);
  let [index, setIndex] = useState(0);
  let [crud, setCrud] = useState({data: {}, changed: {} });
  let user = utils.getUser();


  let [openDialogCupons, setOpenDialogCupons] = useState(false);
  let [campanhaSelecionada, setCampanhaSelecionada] = useState(null);

  view.refresh = function () {
    setIndex(index + 1);
  }

  crud.refresh = function () {
    setIndex(index + 1);
    setCrud(crud);
  }

  const carregaMeusCuponsCampanha = function (el, args) {
    const campanha = args.data;
    if (campanha) {
      setCampanhaSelecionada(campanha);
      crud.refresh();
      switchDialogCupons();
    }
  }
  crud.carregaMeusCuponsCampanha = carregaMeusCuponsCampanha;

  const switchDialogCupons = function () {
    
    if (openDialogCupons === true) {
      setOpenDialogCupons(false);
      openDialogCupons = false;
    } else {
      setOpenDialogCupons(true);
      openDialogCupons = true;
    }
  };

  const handleCloseDialog = function () {
    window.location.reload();
  };

  const campanhas = {
    elements: {
      content: {
        styleClass: 'ui-lista-campanhas',
        elements: {
          lista_campanhas: {
            name: 'lista_campanhas',
            header: false,
            layout: 'clear',
            add: false,
            type: 'list',
            list: {
              url: 'listarCampanhaPremiacaoPorUsuario?id_usuario='+utils.getUser().id
            },
            columns: {
              card: {
                type: 'card',
                style: { height: '150px' },
                rowEdit: true,
                elements: {
                  img: { 
                    field: 'banner', 
                    type: 'img', 
                    space: 3,
                    styleClass: 'campanha-list-banner'
                  },
                  descricao_campanha: {
                    style: { fontSize: '30px' , marginLeft: '20px'},
                    space: 6,
                    type: 'span', 
                    value: '${"<b>"+@this.descricao_campanha + "</b> <br> " + @this.descricao_premio}'
                  },
                  visualizar_meus_cupons: {
                    style: { fontSize: '15px' , top: '40px'},
                    space: 3,
                    rows: 2,
                    type: 'button', 
                    value: utils.lng('meus_cupons','Meus Cupons'),
                    label: utils.lng('ver_meus_cupons','Ver Meus Cupons'),
                    click: {
                      action: 'carregaMeusCuponsCampanha'
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const listaMeusCupons = {
    name: 'lista_cupons',
    layout: 'grid',
    add: false,
    type: 'list',
    search: true,
    list: {
      url: 'listarMeusCupons?id_campanha_premiacao='+(campanhaSelecionada ? campanhaSelecionada.id : 0)+'&id_usuario'+user.id
    },
    columns: {
      icone: { 
        type: 'img', 
        styleClass: 'fas fa-tag',
        space: 2
      },
      numero_cupom: {
        space: 3,
        type: 'span', 
        field: 'numero_cupom',
        label: utils.lng('numero_cupom', 'Número Cupom')
      },
      ativo: {
        space: 1,
        type: 'span', 
        field: 'ativo',
        label: utils.lng('ativo', 'Ativo')
      },
      premiado: {
        space: 1,
        type: 'span', 
        field: 'premiado',
        label: utils.lng('premiado', 'Premiado')
      },
      numero_pedido: {
        space: 3,
        type: 'span', 
        field: 'numero_pedido',
        label: utils.lng('numero_pedido', 'Número Pedido')
      },
      dh_registro: {
        space: 2,
        type: 'span', 
        format: 'date', 
        field: 'dh_registro',
        label: utils.lng('data_cupom', 'Data Cupom')
      }
    }
  };

  window.crud = crud;

  return (
    <View name="lista-campanhas" view={view} >
      <div className="ui-campanhas">
        <Element value={campanhas} crud={crud} data={crud.data} view={view} ></Element>
        
        <Dialog 
          onClose={handleCloseDialog} 
          aria-labelledby="customized-dialog-title" 
          open={openDialogCupons} 
          fullWidth={false}
          maxWidth={false}
          PaperProps={{ style: { width: '60%', height: '60%' } }}
        >
          <DialogTitle id="customized-dialog-title">
            {utils.lng('meus_cupons', 'Meus Cupons')}
          </DialogTitle>
          <DialogContent>
            <Element value={listaMeusCupons} crud={crud} data={crud.data} view={view} ></Element>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={switchDialogCupons} color="primary">
              {utils.lng('fechar', 'FECHAR')}
            </Button>
          </DialogActions>
        </Dialog>
        
      </div>
    </View >
  );
}

export default CampanhasPremiacaoCupons;