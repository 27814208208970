import React, { useState, useEffect } from "react";
import View from './view';
import utils from './utils';

const TransmissaoAoVivo = () => {

  let [ready, setReady] = useState(false);
  let [index, setIndex] = useState(0);
  let [crud, setCrud] = useState({ data: null, changed: null, requireds: {} });

  let transmissao = null;
  let [tituloTransmissao, setTituloTransmissao] = useState('');
  let [url, setUrl] = useState('');
  let parameters = "autoplay=1&modestbranding=1";
  let [isUrlZoom, setIsUrlZoom] = useState(false);
  let [devToolsOpened, setDevToolsOpened] = useState(false);
  let [isTransmissaoIniciada, setIsTransmissaoIniciada] = useState(false);
  let [isAcessoPermitido, setIsAcessoPermitido] = useState(true);
  
  //IMPEDIR ABERTURA DO DEVTOOLS
  document.addEventListener('contextmenu', (event) => event.preventDefault()); // Desativa clique direito

  document.addEventListener('keydown', (event) => {
    // Bloqueia F12, Ctrl+Shift+I, Ctrl+Shift+J, Ctrl+U
    if (event.key === 'F12' || (event.ctrlKey && event.shiftKey && ['I', 'J', 'C'].includes(event.key)) || (event.ctrlKey && event.key === 'U')) {
      event.preventDefault();
      window.dialog.alert(utils.lng('esta_acao_esta_desabilitada','Esta ação está desabilitada!'));
    }
  });

  //
  const detectaDevTools = function () {
    const threshold = 160; // Altura mínima típica do DevTools
    setInterval(() => {
      const widthThreshold = window.outerWidth - window.innerWidth > threshold;
      const heightThreshold = window.outerHeight - window.innerHeight > threshold;
      if (widthThreshold || heightThreshold) {
        devToolsOpened = true;
        setDevToolsOpened(devToolsOpened);
        window.dialog.alert(utils.lng('devtools_detectado_feche_para_continuar','DevTools detectado! Por favor, feche-o para continuar.'));
      } else {
        devToolsOpened = false;
        setDevToolsOpened(devToolsOpened);
        if (!isTransmissaoIniciada && isAcessoPermitido) {
          loadTransmissao();
        }
      }
    }, 500);
  }
  
  //

  const loadTransmissao = function () {
    if (devToolsOpened === false) {
      utils.execute(crud, 'consultaTransmissaoAtivaClienteUsuario?id_usuario='+utils.getUser().id+"&id_cliente"+utils.getCliente().id, null, function (res) {
        if (res.data.length > 0) {
          transmissao = res.data[0];
          transmissao.id = transmissao.id_transmissao;
          if (transmissao && transmissao.id) {
            isAcessoPermitido = false;
            setIsAcessoPermitido(isAcessoPermitido);
            utils.execute(crud, 'transmissao/validaAcesso/'+transmissao.id, null, function (resValidacao) {
              if (resValidacao.data && resValidacao.data.acessoPermitido === true) {
                tituloTransmissao = transmissao.titulo;
                let completeUrl = transmissao.url;

                isUrlZoom = completeUrl.indexOf('zoom') > -1;
                setIsUrlZoom(isUrlZoom);
                if (isUrlZoom) {
                  url = completeUrl;
                  setUrl(url);
                } else {
                  url = completeUrl.indexOf('?') > -1 ? completeUrl+"&"+parameters : completeUrl+"?"+parameters;
                  setUrl(url);
                  // let splittedUrl = completeUrl.split('?');
                  // url = splittedUrl[0]+'?'+parameters;
                }
                isAcessoPermitido = true;
                setIsAcessoPermitido(isAcessoPermitido);
                isTransmissaoIniciada = true;
                setIsTransmissaoIniciada(isTransmissaoIniciada);
              } else {
                isAcessoPermitido = true;
                setIsAcessoPermitido(isAcessoPermitido);
                window.location.href = '/home';
              }
            })
          }
        }
        crud.refresh();
        setCrud(crud);
        setIndex(index + 1);
      }, true);
    }
  };

  useEffect(() => {
    if (!ready) {
      setReady(true);
      detectaDevTools();
      setTimeout(() => {
        loadTransmissao();
      }, 1000);
    }
  });

  return (
    <View name="ui-transmissao">
      <div>
        <iframe
          width="80%" 
          height="800px" 
          src={url}
          title={tituloTransmissao}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          onContextMenu={() => false} 
        ></iframe>
        {/* {!isUrlZoom &&
          <> */}
            <div
              style={{
                position: 'absolute', top: 0, left: 0, width: '100%', height: '1100px', backgroundColor: 'rgba(0, 0, 0, 0)', zIndex: 999,
              }}
              onClick={ (event) => {
                event.stopPropagation();
              }}
            ></div>
            
          {/* </>
        } */}
      </div>
    </View>
  );
};

export default TransmissaoAoVivo;